<template>
  <div :style="backCss" class="timepicker">
    <v-touch @swipeleft="getNextMonthDayList" @swiperight="getPreMonthDayList" class="wrapper">
      <div class="timepicker-box">
        <div class="box-section">
          <div class="section-slider">
            <div class="slider-year-month">
              <div class="slider-add">
                <!-- <a-icon @click="changeViewType" type="calendar" /> -->
              </div>
              <a-icon @click="getPreMonthDayList" type="left" class="pre" />
              <span class="year" style="font-size:18px;">{{ this.monthTitle }}</span>
              <a-icon @click="getNextMonthDayList" type="right" class="next" />
              <div class="slider-add">
                <a-icon v-if="!isAccountCenter" @click="showDrawer" type="search" />
              </div>
            </div>

            <div class="slider-week">
              <span class="week-item" :key="key" v-for="(item, key) in weekText">{{ item }}</span>
            </div>
            <div class="slider-day">
              <div
                :class="['day-item', item.isThisMonth ? '' : 'not-current-month']"
                :key="key"
                v-for="(item, key) in days"
                @click="changeSelItem(item, key)"
              >
                <div
                  :class="[
                    'day',
                    item.isToDay ? 'day-item-current' : '',
                    selIndex == key ? 'day-item-sel' : '',
                    item.propsAttr.length ? 'sel' : ''
                  ]"
                >
                  {{ item.day }}
                  <span :class="getHoliday(item) == 'HOLIDAY' ? 'holiday-span-orange' : 'holiday-span-red'">
                    {{ getHoliday(item) == 'HOLIDAY' ? '假' : getHoliday(item) == 'WORK' ? '班' : '' }}
                  </span>
                  <div :class="getSchedule(item) ? 'is-have-task' : ''"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-touch>
    <a-spin :spinning="mobileLoading">
      <div class="mobile-task-list">
        <div @click="toTaskDetail(item)" v-for="item in mobileTasks" :key="item.id">
          {{
            (item.productSequenceNo ? item.productSequenceNo + '-' : '') +
              item.productionName +
              '-' +
              item.chapterSequenceNo +
              '-' +
              item.stageName +
              '-' +
              item.user.userName
          }}
          <actions-tasks
              v-if="item"
              :isMyTask="isMyTask"
              :isCalendar="true"
              :isMobile="true"
              :loadData="reload"
              :propsParams="item"
            ></actions-tasks>
        </div>
      </div>
    </a-spin>
    <a-drawer
      :height="80"
      :headerStyle="{ display: 'none' }"
      placement="top"
      :closable="false"
      :visible="visible"
      @close="onClose"
    >
      <div style="display:flex">
        <a-select
          mode="multiple"
          showArrow
          placeholder="选择阶段"
          :maxTagCount="0"
          v-model="stageIds"
          style="width: 400px"
          @change="searchQueryForm"
        >
          <a-select-option v-for="stage in stageList" :key="stage.stageId">
            {{ stage.stageName }}
          </a-select-option>
        </a-select>
        <a-input placeholder="作品名称" v-model="productionName" @blur="searchQueryForm" />
        <a-month-picker
          class="mobile-picker"
          format="YYYY-MM-DD"
          v-model="settlementMonth"
          placeholder="请选择结算月"
          @change="searchQueryForm"
        />
      </div>
    </a-drawer>
  </div>
</template>
<script>
import { formatDate, returnData, getFirstAndLast, isToday } from './day.js'
import ActionsTasks from '@/views/system/modules/mytasks/actionsTasks'
export default {
  name: 'MobileCalendar',
  components: {
    ActionsTasks
  },
  props: {
    //有任务的日期
    //dataList: {
    //	require: false,
    //	type: Array,
    //	default: () => []
    //}
    holiday: {
      type: Array,
      default: () => []
    },
    schedule: {
      type: Array,
      default: () => []
    },
    stageList: {
      type: Array,
      default: () => []
    },
    mobileTasks: {
      type: Array,
      default: () => []
    },
    mobileLoading: {
      type: Boolean,
      default: false
    },
    isMyTask: {
      type: Boolean,
      default: false
    },
    isAccountCenter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stageIds: [],
      productionName: '',
      settlementMonth: '',
      mobileViewType: 'taskList',
      selDay: {},
      weekText: ['日', '一', '二', '三', '四', '五', '六'],
      currentDay: '', //当前几号
      currentYear: '', //当前年
      currentMonth: '', // 当前月
      currentWeek: '', // 当前月1号是星期几？ 0表示星期天
      monthTitle: '',
      dataList: [
        {
          bgcolor: '#67b7f0',
          id: 1180,
          className: '二级建造师考试',
          data: '2022-06-26'
        }
      ], //有任务的日期
      thisMonthDay: [],
      days: [], //所有日期
      firstDay: new Date(),
      weekDay: new Date().getDay(), //获取今天是周几
      selIndex: -1,
      visible: false
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  created() {
    this.firstDay.setDate(this.firstDay.getDate() - this.weekDay) //获取本周第一天的日期
    this.initData()
  },
  methods: {
    searchQueryForm() {
      this.$emit('clickCurrentDaySearch', {
        days: this.days,
        stageIds: this.stageIds,
        productionName: this.productionName,
        settlementMonth: this.settlementMonth
      })
    },
    onClose() {
      this.visible = false
    },
    showDrawer() {
      this.visible = true
    },
    toTaskDetail(record) {
      this.$router.push({
        path: `/translate/mytasks/detail/${record.id}/${record.productionId}/${
          record.chapterId
        }?record=${encodeURIComponent(
          JSON.stringify({
            platformId: record.platformId
          })
        )}`
      })
    },
    changeViewType() {
      if (this.mobileViewType == 'taskList') {
        this.mobileViewType = 'eventList'
      } else {
        this.mobileViewType = 'taskList'
      }
    },
    getHoliday(item) {
      let text = ''
      text = this.holiday.find(i => {
        return item.data == i.date
      })
        ? this.holiday.find(i => {
            return item.data == i.date
          }).type
        : ''
      return text
    },
    reload() {
      this.$emit('clickCurrentDay', { ...this.selDay, days: this.days })
    },
    getSchedule(item) {
      let data = 0
      data = this.schedule.find(i => {
        return item.data.replaceAll('-', '') == i.key
      })
        ? this.schedule.find(i => {
            return item.data.replaceAll('-', '') == i.key
          }).data
        : 0
      return data && data.length > 0 ? true : false
    },
    // 选中日期
    changeSelItem(item, index) {
      this.selIndex = index
      this.selDay = item
      this.$emit('clickCurrentDay', { ...item, days: this.days })
    },
    // 下月
    getNextMonthDayList() {
      this.pickNext(this.currentYear, this.currentMonth)
    },
    // 上月
    getPreMonthDayList() {
      this.pickPre(this.currentYear, this.currentMonth)
    },
    // 上一月 （@param year, @param month）
    pickPre(year, month) {
      console.log('上一月')
      var d = new Date(formatDate(year, month, 1))
      d.setDate(0) //设置为上个月
      this.initData(formatDate(d.getFullYear(), d.getMonth() + 1, 1))
      this.$emit('currentYM', {
        year: this.currentYear,
        month: this.currentMonth
      })
    },
    // 下一个月 （@param year, @param month）
    pickNext(year, month) {
      console.log('下一个月')
      var d = new Date(formatDate(year, month, 1))
      d.setDate(35)
      this.initData(formatDate(d.getFullYear(), d.getMonth() + 1, 1))
      this.$emit('currentYM', {
        year: this.currentYear,
        month: this.currentMonth
      })
    },
    // 初始化月
    initData(cur) {
      var date
      if (cur) {
        // 切换上一月、下一月
        date = new Date(cur)
      } else {
        var now = new Date() // 此处取本机时间，应改为服务器时间
        // console.log(now.getFullYear(), now.getMonth() + 1, 1)
        var date = new Date(formatDate(now.getFullYear(), now.getMonth() + 1, 1))
      }

      this.currentDay = new Date().getDate() // 今日日期 几号
      this.currentYear = date.getFullYear() // 当前年份
      this.currentMonth = date.getMonth() + 1 // 当前月份
      this.currentWeek = date.getDay() // 当前月1号是星期几？ 0表示星期天
      // console.log(this.currentYear, this.currentMonth)
      var month = this.currentMonth >= 10 ? this.currentMonth : '0' + this.currentMonth
      this.monthTitle = this.currentYear + '-' + month
      // 当前月最后一天是星期几？ 0表示星期天
      var nextWeek = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDay()
      var str = formatDate(this.currentYear, this.currentMonth, 1) // 2021/05/01
      var nextStr = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString() // 2021/05/31
      var daysList = [] // 初始化日期
      // 设置上一个月 需显示 的最后几天  铺满一周
      for (var i = this.currentWeek; i > 0; i--) {
        var d = new Date(str)
        d.setDate(d.getDate() - i)
        var tod = returnData(d) //获取完整日期
        var dayobject = {
          d: tod,
          data: tod,
          day: d.getDate(),
          isThisMonth: d.getMonth() + 1 == this.currentMonth ? true : false,
          isToDay: isToday(tod),
          isSign: this.isVerDate(d),
          propsAttr: this.copyAttr(d, this.dataList)
        } // 用一个对象包装Date对象  以便为以后预定功能添加属性
        daysList.push(dayobject) // 将日期放入data 中的days数组 供页面渲染使用
      }
      // 显示当前月的天数  第二个循环为 j<= 36- currentWeek，
      // 因为1号是星期六的时候当前月需显示6行，如2020年8月
      var num = 0 //第几个月 每遇到1号加1
      for (var j = 0; j <= 36 - this.currentWeek; j++) {
        var d = new Date(str)
        d.setDate(d.getDate() + j)
        var tod = returnData(d) //获取完整日期
        var dddd = d.getDate()
        var dayobject = {
          d: tod,
          data: tod,
          day: d.getDate(),
          isThisMonth: d.getMonth() + 1 == this.currentMonth ? true : false,
          isToDay: isToday(tod),
          isSign: this.isVerDate(d),
          propsAttr: this.copyAttr(d, this.dataList)
        }
        if (dddd == 1) {
          num++
        }
        if (num == 2) {
          break
        }
        daysList.push(dayobject)
      }
      // 设置下一个月 需显示 的最前几天铺满一周
      for (var k = 1; k <= 6 - nextWeek; k++) {
        var d = new Date(nextStr)
        d.setDate(d.getDate() + k)
        var tod = returnData(d) //获取完整日期
        var dayobject = {
          d: tod,
          data: tod,
          day: d.getDate(),
          isThisMonth: d.getMonth() + 1 == this.currentMonth ? true : false,
          isToDay: isToday(tod),
          isSign: this.isVerDate(d),
          propsAttr: this.copyAttr(d, this.dataList)
        } // 用一个对象包装Date对象  以便为以后预定功能添加属性
        daysList.push(dayobject) // 将日期放入data 中的days数组 供页面渲染使用
      }
      // 设置本月的所有日期
      this.thisMonthDay = daysList.filter((item, index) => {
        if (isToday(item.d)) {
          this.selIndex = index
        }
        return item.isThisMonth
      })
      this.days = daysList
      this.selDay = this.days.find(item => {
        return item.isToDay
      })
      this.$emit('clickCurrentDay', {
        ...this.days.find(item => {
          return item.isToDay
        }),
        days: this.days
      })
      // console.log(daysList)
    },
    // 判断该日期是否有任务
    isVerDate(d) {
      let dda = returnData(d)
      this.signdays = []
      for (var i in this.sign_Month) {
        this.signdays.push(this.sign_Month[i].data)
      }
      return this.signdays.includes(dda)
    },
    // 复制元素的所有属性
    copyAttr(d, List, type) {
      // 日期   原数组
      var dd = null
      if (type) {
        dd = d
      } else {
        dd = returnData(d)
      }
      var ArrayList = []
      if (List.length > 0) {
        List.forEach(item => {
          if (item.data == dd) {
            // item.tt = getHourAndMin(item.start) + '-' + getHourAndMin(item.end)
            ArrayList.push(item)
          }
        })
        return ArrayList
      } else {
        return []
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .wrapper {
  touch-action: pan-y !important;
}
.mobile-task-list {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  > div {
    background-color: rgb(248, 248, 248);
    margin-top: 8px;
    padding-left: 19px;
    padding-right: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.timepicker {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  .timepicker-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: #fff;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    .box-section {
      position: relative;
      flex: 1;

      .section-slider {
        display: flex;
        flex-direction: column;

        .slider-year-month {
          padding-top: 6px;
          padding-bottom: 6px;
          color: var(--theme-color);
          display: flex;
          align-items: center;
          justify-content: space-between;

          .pre {
            width: 20px;
            height: 20px;
            line-height: 24px;
          }

          .next {
            width: 20px;
            height: 20px;
            line-height: 24px;
          }
          .slider-add {
            width: 14.28%;
            text-align: center;
          }
        }
        .slider-week {
          display: flex;
          align-items: center;
          color: #333;

          .week-item {
            flex: 1;
            font-size: 14px;
            padding: 7px 0 5px;
            text-align: center;
          }
        }

        .slider-day {
          padding: 5px 0;
          display: flex;
          flex-wrap: wrap;
          .day-item {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #333;
            width: 14.28%;
            height: 40px;
            text-align: center;
            font-size: 14px;

            .day {
              width: 20px;
              line-height: 20px;
              height: 20px;
              border-radius: 5px;
              position: relative;
            }
            .holiday-span-red {
              font-size: 12px;
              position: absolute;
              top: -8px;
              right: -11px;
              color: red;
              transform: scale(0.7);
            }
            .holiday-span-orange {
              font-size: 12px;
              position: absolute;
              right: -11px;
              top: -8px;
              color: rgb(251, 172, 0);
              transform: scale(0.7);
            }
            .is-have-task {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              margin: 0 auto;
              margin-top: 3px;
              background-color: rgba(0, 0, 0, 0.25);
            }
            .sel:after {
              position: absolute;
              display: block;
              content: '';
              width: 3px;
              height: 3px;
              margin: auto;
              border-radius: 50%;
              background: #677fd2;
              left: 0;
              right: 0;
              bottom: 0;
            }
          }

          .day-item-current {
            color: #fff !important;
            background-color: var(--theme-color);
          }

          .day-item-sel {
            border: 1px solid var(--theme-color);
            color: var(--theme-color);
          }

          .not-current-month {
            .day {
              color: #c9c6c6;
            }
          }
        }
      }
    }

    .box-footer {
      flex-basis: 10%;
      display: flex;
      box-shadow: 0px -1px 0px #eee;

      .footer-btn {
        flex: 1;
        text-align: center;

        span {
          font-size: 16px;
        }

        &:not(:last-child) {
          box-shadow: 1px 0px 0px #eee;
        }
      }
    }
  }
}
.app-list .timepicker {
  padding-left: 20px !important;
}
</style>
